import Image from "next/image";
import { Container } from "reactstrap";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function ClutchLogoSection() {
  const logoContainerRef = useRef(null);
  const logosRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        logosRef.current,
        { opacity: 0, y: 50 }, // Start position (lower opacity and below original position)
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          stagger: 0.3, // Stagger effect for sequential animation
          scrollTrigger: {
            trigger: logoContainerRef.current,
            start: "bottom 70%", // Animation starts when container reaches 80% of viewport height
            toggleActions: "play none none reverse",
          },
        }
      );

      // Refresh ScrollTrigger on load
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    });

    return () => ctx.revert();
  }, []);

  return (
    <Container className="p-b-section">
      <div
        ref={logoContainerRef}
        className="grid justify-between grid-cols-3 md:grid-cols-6 lg:grid-cols-6 items-center justify-items-center gap-[30px] capitalize cls-main"
      >
        {["https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-01.webp",
          "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-02.webp",
          "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-03.webp",
          "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-04.webp",
          "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-05.webp",
          "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/It-logo-clutch-06.webp"
        ].map((src, index) => (
          <Image
            key={index}
            ref={(el) => (logosRef.current[index] = el)}
            className="object-contain cls-logo"
            src={src}
            alt={`clutch-logo-${index + 1}`}
            width={index % 2 === 0 ? 92 : 148}
            height={124}
            loading="lazy"
          />
        ))}
      </div>
      <div className="text-center text-[12px] lg:text-[14px] text-[--text-color] pt-[50px]">
        We set new standards and inspire innovation, empowering businesses to
        thrive in a dynamic world.
      </div>
    </Container>
  );
}